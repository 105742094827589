<template>
  <div class="page template-1">
    <header class="page-header container">
      <h1>{{ mrpProgramInfo.name }}</h1>
    </header>
    <!-- / Page Header -->
    <b-container class="page-body">
      <b-row class="center-elements">
        <b-col sm="6" class="search-col">
          <search-form :i18n="translations.components" />
        </b-col>
      </b-row>
      <b-row v-if="mrpProgramInfo.videoListing">
        <b-col sm="12" class="video-col">
          <h4 id="featuredVideo">{{ mrpProgramInfo.featuredVideoText }}</h4>
          <div class="embed-responsive embed-responsive-16by9" id="videoPlayerContainer">
            <vueVimeoPlayer
              ref="player"
              :video-id="videoID"
              :player-height="720"
              :autoplay="false"
              @ready="this.onReady"
            ></vueVimeoPlayer>
          </div>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="6" xs="12" class="tile-col mt-5">
          <card
            :category="`topics`"
            :items="topics"
            :fields="topicFields"
            :cardTitle="mrpProgramInfo.topicTitle"
            :btnText="mrpProgramInfo.viewMoreText"
            :btnUrl="`/resources/media-portal/program/topics`"
            :showLinkButton="true"
            :sortOptions="topicSortOptions"
            :sortBy="`TopicUpdated`"
            :translations="translations"
            @sort_changed="sortChanged($event)"
            @name_link_click="nameLinkClick($event)"
            class="tile-col-card"
          >
          </card>
        </b-col>
        <b-col sm="6" xs="12" class="tile-col mt-5">
          <card
            :category="`resources`"
            :items="resources"
            :fields="resourcesFields"
            :cardTitle="mrpProgramInfo.resourceTitle"
            :btnText="mrpProgramInfo.viewMoreText"
            :btnUrl="`/resources/media-portal/program/resources`"
            :showLinkButton="true"
            :sortOptions="resourceSortOptions"
            :sortBy="`ResourceUpdated`"
            :translations="translations"
            @sort_changed="sortChanged($event)"
            @name_link_click="nameLinkClick($event)"
            class="tile-col-card"
          >
          </card>
        </b-col>
        <b-col sm="6" xs="12" class="tile-col mt-5">
          <card
            :category="`videos`"
            :items="videos"
            :fields="videosFields"
            :cardTitle="mrpProgramInfo.videoTitle"
            :btnText="mrpProgramInfo.viewMoreText"
            :btnUrl="`/resources/media-portal/program/videos`"
            :showLinkButton="true"
            :sortOptions="videoSortOptions"
            :sortBy="`VideoUpdated`"
            :translations="translations"
            @sort_changed="sortChanged($event)"
            @name_link_click="nameLinkClick($event)"
            class="tile-col-card"
          >
          </card>
        </b-col>
        <b-col sm="6" xs="12" class="tile-col mt-5">
          <card
            :category="`links`"
            :items="links"
            :fields="linksFields"
            :cardTitle="mrpProgramInfo.linkTitle"
            :btnText="mrpProgramInfo.viewMoreText"
            :btnUrl="`/resources/media-portal/program/links`"
            :showLinkButton="true"
            :sortOptions="linkSortOptions"
            :sortBy="`LinkUpdated`"
            :translations="translations"
            @sort_changed="sortChanged($event)"
            @name_link_click="nameLinkClick($event)"
            class="tile-col-card"
          >
          </card>
        </b-col>
      </b-row>
    </b-container>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import Card from './CardMRPProgram.vue'
import searchForm from './SearchForm.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  name: 'mrp-program',
  mixins: [translationMixin],
  breadcrumb() {
    return {
      label: this.programTitle,
      parent: 'media-resource-portal',
    }
  },
  data() {
    return {
      translations: {},
      itemsDisplayCount: 3,
      videoID: '288800270',
      playerReady: false,

      topicSort: 'TopicUpdated',
      topicSortOptions: [
        {
          value: 'TopicName',
          text: '', // Name
        },
        {
          value: 'TopicUpdated',
          text: '', // 'Most Recent'
        },
      ],
      topicFields: [
        {
          key: 'nameLink',
          label: 'nameLink',
          thStyle: {
            display: 'none',
          },
        },
      ],
      resourceSort: 'ResourceUpdated',
      resourceSortOptions: [
        {
          value: 'ResourceName',
          text: '', // 'Name'
        },
        {
          value: 'ResourceUpdated',
          text: '', // 'Most Recent
        },
      ],
      resourcesFields: [
        {
          key: 'icon',
          label: 'icon',
          thStyle: {
            display: 'none',
          },
          tdClass: 'mrpIconClass',
        },
        {
          key: 'nameLink',
          label: 'nameLink',
          thStyle: {
            display: 'none',
          },
        },
        {
          key: 'download_link',
          label: 'download_link',
          thStyle: {
            display: 'none',
          },
          tdClass: 'mrpDownloadClass',
        },
      ],
      videoSort: 'VideoUpdated',
      videoSortOptions: [
        {
          value: 'VideoName',
          text: '', // 'Name'
        },
        {
          value: 'VideoUpdated',
          text: '', // 'Most Recent'
        },
      ],
      videosFields: [
        {
          key: 'icon',
          label: 'icon',
          thStyle: {
            display: 'none',
          },
          tdClass: 'mrpIconClass',
        },
        {
          key: 'nameLink',
          label: 'nameLink',
          thStyle: {
            display: 'none',
          },
        },
        {
          key: 'download_link',
          label: 'download_link',
          thStyle: {
            display: 'none',
          },
          tdClass: 'mrpDownloadClass',
        },
      ],
      linkSort: 'LinkUpdated',
      linkSortOptions: [
        {
          value: 'LinkName',
          text: '', // 'Name'
        },
        {
          value: 'LinkUpdated',
          text: '', // 'Most Recent'
        },
      ],
      linksFields: [
        {
          key: 'nameLink',
          label: 'nameLink',
          thStyle: {
            display: 'none',
          },
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPSelectedTopicId: 'mrp/setMRPSelectedTopicId',
      setMRPSelectedVideoId: 'mrp/setMRPSelectedVideoId',
    }),
    createTitle(code) {
      return {
        ax: 'Auxiliary',
        cm: 'Church Ministry',
        co: 'Officer',
        gc: 'GideonCard',
        ff: 'Faith Fund',
        in: 'International',
        mb: 'Membership',
        of: 'Officer',
        pw: 'Prayer & Personal Witnessing',
        sd: 'Scripture Distribution',
        st: 'Officer',
        xx: 'Program',
      }[code || 'xx']
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.loadMRPProgramInfo({
          lang: this.prefCulture,
          restype: 'all',
          programid: this.mrpSelectedProgramId,
        }),
      ]).then(() => {
        if (this.mrpProgramInfo.videoListing.length > 0) {
          this.showVideo(this.videos[0].video_link, this.videos[0].download_link, this.videos[0].nameLink)
        }
        this.setLoadingStatus(false)
      })
    },
    nameLinkClick(data) {
      switch (data.category) {
        case 'topics':
          this.setMRPSelectedTopicId(data.itemId)
          this.$router.push({ name: 'mrp-topic-detail' })
          break
        case 'resources':
          window.open(data.link_url, '_blank')
          break
        case 'videos':
          this.setMRPSelectedVideoId(data.itemId)
          this.showVideo(data.video_link, data.download_link, data.nameLink)
          break
        case 'links':
          window.open(data.link_url, '_blank')
          break

        default:
          break
      }
    },
    sortChanged(data) {
      switch (data.programCategory) {
        case 'topics':
          this.topicSort = data.sortBy
          break
        case 'resources':
          this.resourceSort = data.sortBy
          break
        case 'videos':
          this.videoSort = data.sortBy
          break
        case 'links':
          this.linkSort = data.sortBy
          break
        default:
          break
      }
    },
    showVideo(fileId, fileDownloadLink, filename) {
      this.videoID = fileId.replace('https://player.vimeo.com/video/', '')
      this.videoFileDownloadLink = fileDownloadLink
      this.videoName = filename
      this.$refs.player.pause()
      this.displayVideo = 'block'
      var elmnt = document.getElementById('featuredVideo')
      elmnt.scrollIntoView()
    },
    onReady() {
      this.playerReady = true
    },
    sortTheArray(listingArray, sortOn, makeDesc) {
      let s1 = makeDesc ? -1 : 1
      let s2 = makeDesc ? 1 : -1
      return listingArray.sort((a, b) => (a[sortOn] > b[sortOn] ? s1 : s2))
    },
    loadTranslations() {
      this.topicSortOptions = [
        {
          value: 'TopicName',
          text: this.translations.tcName,
        },
        {
          value: 'TopicUpdated',
          text: this.translations.tcMostRecent,
        },
      ]
      this.resourceSortOptions = [
        {
          value: 'ResourceName',
          text: this.translations.tcName,
        },
        {
          value: 'ResourceUpdated',
          text: this.translations.tcMostRecent,
        },
      ]
      this.videoSortOptions = [
        {
          value: 'VideoName',
          text: this.translations.tcName,
        },
        {
          value: 'VideoUpdated',
          text: this.translations.tcMostRecent,
        },
      ]
      this.linkSortOptions = [
        {
          value: 'LinkName',
          text: this.translations.tcName,
        },
        {
          value: 'LinkUpdated',
          text: this.translations.tcMostRecent,
        },
      ]
    },
  },
  computed: {
    ...mapGetters({
      mrpProgramInfo: 'mrp/mrpProgramInfo',
      mrpSelectedProgramId: 'mrp/mrpSelectedProgramId',
      mrpSelectedVideoId: 'mrp/mrpSelectedVideoId',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
    }),
    videos() {
      this.sortTheArray(this.mrpProgramInfo.videoListing, this.videoSort, this.videoSort.includes('Updated'))
      let newVideoList = this.mrpProgramInfo.videoListing.map((v) => {
        return {
          category: 'videos',
          itemId: v.VideoId,
          nameLink: v.VideoName,
          icon: v.VideoThumbnailId,
          icon_alt_text: 'Click to play',
          video_link: v.VideoLink,
          downloadIcon: '479F189B-353E-48B3-817E-FC784AC59C1B', //GUID in sitecore for download icon
          download_link: v.VideoDownload,
          link_url: '',
          updated: v.VideoUpdated,
        }
      })
      return newVideoList.slice(0, this.itemsDisplayCount)
    },
    topics() {
      this.sortTheArray(this.mrpProgramInfo.topicListing, this.topicSort, this.topicSort.includes('Updated'))
      let newTopicList = this.mrpProgramInfo.topicListing.map((t) => {
        return {
          category: 'topics',
          itemId: t.TopicId,
          nameLink: t.TopicName,
          icon: '',
          icon_alt_text: '',
          download_link: '',
          link_url: '/resources/media-portal/program/topics',
          updated: t.TopicUpdated,
        }
      })
      return newTopicList.slice(0, this.itemsDisplayCount)
    },
    links() {
      this.sortTheArray(this.mrpProgramInfo.linkListing, this.linkSort, this.linkSort.includes('Updated'))
      let newLinkList = this.mrpProgramInfo.linkListing.map((l) => {
        return {
          category: 'links',
          itemId: l.LinkId,
          nameLink: l.LinkName,
          icon: '',
          icon_alt_text: '',
          download_link: '',
          link_url: l.LinkUrl.replace('[ind_key]', this.userId),
          updated: l.LinkUpdated,
        }
      })

      return newLinkList.slice(0, this.itemsDisplayCount)
    },
    resources() {
      this.sortTheArray(this.mrpProgramInfo.resourceListing, this.resourceSort, this.resourceSort.includes('Updated'))
      let newResourceList = this.mrpProgramInfo.resourceListing.map((r) => {
        return {
          category: 'resources',
          itemId: r.ResourceId,
          nameLink: r.ResourceName,
          icon: r.ResourceThumbnailId,
          icon_alt_text: r.ResourceType,
          downloadIcon: '479F189B-353E-48B3-817E-FC784AC59C1B', //GUID in sitecore for download icon
          download_link: r.ResourceLink,
          link_url: r.ResourceLink,
          updated: r.ResourceUpdated,
        }
      })

      return newResourceList.slice(0, this.itemsDisplayCount)
    },
    programTitle() {
      return !!this.mrpProgramInfo.name ? this.mrpProgramInfo.name : 'Program'
    },
  },
  async created() {
    //need a selected mrp program id
    if (!this.mrpSelectedProgramId) {
      this.$router.push('/resources/media-portal')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('mrp-search-form'),
      this.pageLoad(),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.loadTranslations()
    })
  },
  components: {
    card: Card,
    SearchForm: searchForm,
    vueVimeoPlayer: vueVimeoPlayer,
  },
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
.page {
  .page-body {
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 10px;
    }
  }
}

.video-col {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .embed-responsive-16by9::before {
    padding-top: 43%;
  }
}
.tile-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  .tile-col {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    .tile-col-card {
      flex-grow: 2;
    }
  }
}

#videoPlayerContainer {
  max-height: 400px;
}
</style>
